.player{
  background-color: #242424;
  box-sizing: border-box;
  border-bottom: 1px solid #000;
  position: relative;
  height: calc(100% - 70px);

}
.player .player-header{
  width: 100%;
  height: 70px;
}
.player .player-header .wrap{
  width: 1100px;
  margin: 0 auto;
  height: 100%;
}
.player .player-header .m-nav{
  display: flex;
  align-items: center;
  height: 100%;
}

.m-nav .m-nav-li{
  color: #ccc;
}
.m-nav .active-li{
  color: #fff;
  background: #000;
  height: 100%;
  line-height: 70px;
  padding: 0 19px;
}
.player-body{
  background-color: #f5f5f5;
  position: relative;
  z-index: 10;
  height: calc(100% - 70px);
}
.player-body .wrap{
  width: 980px;
  min-height: 700px;
  margin: 0 auto;
}
.w-full{
  width: 100%;
}
.h-75{
  height: 300px;
}
.player .blur-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    filter: blur(90px);
    background-repeat: no-repeat;
    background-size: cover;
}
.player .blur-bg-mask{
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: rgba(0, 0, 0, 0.35);
}
