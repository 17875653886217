.footer{
  width: 100%;
  height: 70px;
  background-color: #242424;
  .wrap{
    width: 1100px;
    margin: 0 auto;
    height: 100%;
  }
  
}
.footer a {
    text-decoration: none
}