body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body{
  overflow: hidden;
}
body, html, h1, h2, h3, h4, h5, h6, ul, ol, li, dl, dt, dd, header, menu, section, p, input, td, th, ins {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}
.mt-15{
  margin-top: 60px;
}
.mb-5{
  margin-bottom: 20px;
}
.title-box .title{
  font-size: 26px;
  font-weight: 500;
  color: #000000;
}
.w-80{
  width: 320px;
}
.h-80{
  height: 320px;
}
.rounded-lg{
  border-radius: 8px;
}
.flex{
  display: flex;
}
.flex-col{
  flex-direction: column;
}
.h-full{
  height: 100%;
}
.w-full{
  width: 100%;
}
.text-sm{
  font-size: 14px;
  line-height: 20px;
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}
.left-0{
  left: 0;
}
.top-0{
  top:0 
}
.mt-2{
  margin-top: 8px;
}
.h-75{
  height: 300px;
}
.cursor-pointer{
  cursor: pointer;
}
.w-12{
  width: 48px;
}
.h-12{
  height: 48px;
}
.items-center{
  align-items: center;
}
.justify-center{
  justify-content: center;
}
.w-30{
  width: 120px;
}
.h-30{
  height: 120px;
}
.w-10{
  width: 40px;
}
.h-10{
  height: 40px;
}
.flex-wrap{
  flex-wrap: wrap;
}
.justify-items-stretch{
  justify-items: stretch;
}
.mr-2{
  margin-right: 8px;
}
.text-black{
  color: #000;
}
.mb-2{
  margin-bottom: 8px;
}
.shrink-0{
  flex-shrink: 0;
}
.mb-1{
  margin-bottom: 4px;
}
.justify-between{
  justify-content: space-between;
}
.mr-4{
  margin-right: 16px;
}
.text-xs{
  font-size: 12px;
  line-height: 16px;
}
.w-15{
  width: 60px;
}
.h-15{
  height: 60px;
}
.w-65{
  width: 260px;
}
.h-65{
  height: 260px;
}
.text-white{
  color: #fff;
}
.text-2xl{
  font-size: 24px;
}
.py-2{
  padding-top: 8px;
  padding-bottom: 8px;
}
.px-4{
  padding-left: 16px;
  padding-right: 16px;
}
.pb-10{
  padding-bottom: 40px;
}
.overflow-y-auto{
  overflow-y: auto;
}
.h-screen{
  height: 100vh;
}
.w-4{
  width: 16px;
}
.h-5{
  height: 20px;
}

