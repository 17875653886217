.home{
  background-color: #242424;
  box-sizing: border-box;
  /* border-bottom: 1px solid #000; */
  position: relative;
  /* height: 100%; */
}
.home .home-header{
  width: 100%;
  height: 70px;
}
.home .home-header .wrap{
  width: 1100px;
  margin: 0 auto;
  height: 100%;
}
.home .home-header .m-nav{
  display: flex;
  align-items: center;
  height: 100%;
}

.m-nav .m-nav-li{
  color: #ccc;
}
.m-nav .active-li{
  color: #fff;
  background: #000;
  height: 100%;
  line-height: 70px;
  padding: 0 19px;
}
.home-body{
  background-color: #f5f5f5;
  /* height: calc(100vh - 70px); */
  overflow-y: auto;
  .carousel-img{
    width: 100%;
    height: 560px;
  }
}
.home-body .wrap{
  width: 980px;
  min-height: 700px;
  margin: 0 auto;
}
.home-body .wrap .img-box{
  &:hover{
    .img-mask{
      opacity: 1;
    }
  }
  
}
.home-body .wrap .img-mask{
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
}
/* .wrap .title{
  font-size: 26px;
  font-weight: 500;
  color: #000000;
} */
